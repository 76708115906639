// react
import React, { useEffect, useState} from 'react'
// react-boostrap
import { Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

// css_components
import './styles/history.css'
const API_URL = process.env.REACT_APP_BASE_URL + '/v1/ld/pull'
const LIST_CIA_URL = process.env.REACT_APP_BASE_URL + '/v1/ld/companyList'


const PullRecords = (data={}) => {
  const [loading, setLoading] = useState(false);
  const [lastModifiedDate, setLastModifiedDate] = useState();
  const [ sourceVrsId, setSourceVrsId ] = useState("")
  // const [ token, setToken ] = useState("c324e8d108e4dcc221d1a6c4ef78fc2fcb776e99")
  const [ companies, setCompanies ] = useState([])
  const [records, setRecords] = useState([]);
  
  const setDate = (ev) => {
    const date = ev.target.value
    setLastModifiedDate(date)
  }

  const getCompanies = async () => {
    const performGetCompanies = async () => {
      const headers = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${localStorage.getItem('token')}`
        },
      };
    const response = await fetch(LIST_CIA_URL, headers)
    const jsonResponse = await response.json()
    return jsonResponse
    }
    const results = await performGetCompanies()
    if(results){
      setCompanies(results)
    }
  };


  const pullData = async () => {
    if(lastModifiedDate){
      setLoading(true);
      const performPull = async (data) => {
        setRecords([])
        const headers = {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(data),
        };
      
        const response = await fetch(API_URL, headers);
        const jsonResponse = await response.json()
        return jsonResponse
      };
  
      const results = await performPull({
        "source_vrs_id": sourceVrsId,
        "last_modified_datetime": new Date(lastModifiedDate).toISOString(),
        "username": localStorage.getItem('username'),
        "user_id": localStorage.getItem('user')
      })
      if(results.pulled_entries){
        setRecords(results.pulled_entries)
      }else{
        alert("There is no data with that date")
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    getCompanies()
  }, [sourceVrsId])
 

  return (
      <div className="container">
          <h3 className="mt-4"> Pull records</h3>
          <label htmlFor="cia-selector">Select company you want to pull records from: </label>
          <select defaultValue="defaultOption" name="" id="cia-selector"  onChange={(e) => setSourceVrsId(e.target.value)}>
            <option value="defaultOption" disabled>Select an option</option>
            {companies.map((company, index) => {
              return <option value={company.sourceVrsId} key={index}>{company.source_name}-{company.sourceVrsId}</option>
            })}
          </select>
          <div className="flex items-center gap-x">
            <label> When was the last time you updated your data? {lastModifiedDate}</label>
            <div className="input-date">
              <input type="date" className="form-control" onChange={setDate} />
            </div>
            <Button variant="primary" type="button" onClick={pullData}>
              {loading? "Pulling records, please wait...": "Pull now"}
            </Button>
          </div>
          <Table className="mt-4 table-dark"striped bordered hover size="sm">
              <thead>
              <tr>
                <th>Nro</th>
                <th>ci</th>
                <th>endExpDate</th>
                <th>gtin</th>
                <th>lastModifiedDateTime</th>
                <th>nextRecordOwner</th>
                <th>recordGuid</th>
                <th>recordOwner</th>
                <th>startExpDate</th>
                <th>status</th>
              </tr>
              </thead>
              <tbody>
              {
                records.map((record, index) => {
                    return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{record.ci}</td>
                      <td>{record.endExpDate}</td>
                      <td>{record.gtin}</td>
                      <td>{record.lastModifiedDateTime}</td>
                      <td>{record.nextRecordOwner}</td>
                      <td>{record.recordGuid}</td>
                      <td>{record.recordOwner}</td>
                      <td>{record.startExpDate}</td>
                      <td>{record.status}</td>
                    </tr>
                    )
                })
              }
              </tbody>
          </Table>
      </div>
  )
}

export default PullRecords

