import HerculesRx from "../services/herculesrxService"

export const login = ( async (payload) => {
    const response = await HerculesRx.fetchLogin(payload)
    return response;
})

export const logout = ( async (tokenSession) =>{
    const response = await HerculesRx.fetchLogout(tokenSession);
    return response;
})

export const pushSync = ( async (payload) =>{
    const response = await HerculesRx.fetchPushSync(payload);
    return response;
})

export const gs1Verification = ( async (url_query, payload) =>{
    const response = await HerculesRx.fetchVerification(url_query, payload);
    return response;
})

export const gtinVerification = ( async (payload) =>{
    const response = await HerculesRx.fetchGtin(payload);
    return response;
})

export const userCreate = ( async (payload) => {
    const response = await HerculesRx.fetchUser(payload);
    return response;
})

export const history = ( async (payload) =>{
    const response = await HerculesRx.fetchHistory(payload);
    return response;
})

export const historyUser = ( async (payload) =>{
    const response = await HerculesRx.fetchUserHistory(payload);
    return response;
})

export const barCode = ( async (payload) => {
    const response = await HerculesRx.fetchBarCode(payload);
    return response;
})

export const syncCia = ( async (payload) => {
    const response = await HerculesRx.fetchSyncCia(payload);
    return response;
})