const base_url = process.env.REACT_APP_BASE_URL;

export class BaseService{
    static baseRequest(url, method, body, headers){
        return new Promise((resolve, reject) =>{
            fetch(`${base_url}/${url}`,
            {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: body ? JSON.stringify(body): null
            }).then(async (response) => {
                let data = null;
                let error = null;
                response.json().then((json) =>{
                    data = json;
                }).catch((err)=>{
                    error = err;
                }).finally(() =>{
                    resolve({
                        "data": data,
                        "statusCode": response.status,
                        "success": response.ok,
                        "error": error
                    })
                }); 
            }).catch((err) =>{
                resolve({
                    "data": null,
                    "statusCode": null,
                    "success": false,
                    "error": err
                })
            })
        })
    }
}