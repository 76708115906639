// React and react-router
import React, {useState} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

// React - Boostrap
import { Navbar, Nav, Container } from 'react-bootstrap';

// Boostrap
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import History from './components/history'
import FormGtin from './components/formGtin'
import FormSync from './components/formPushSync';
import SignIn from './components/signIn'
// import SignUp from './components/signUp'
import { logout } from './actions/herculesrx';
import PullRecords from './components/PullRecords';
import SourceCompany from './components/FormCompany';
require('dotenv').config();

function App() {
  const [typeUser, setTypeUser] = useState(localStorage.getItem('type_user') ||"customer")
  const [sessionToken, setSessionToken] = useState(localStorage.getItem('token') || false)
  const [user, setUser] = useState(localStorage.getItem('user') || "")


  const showHistoryNav = (typeUser) => {
    if(typeUser === "employee") {
      return <Link to="/history" className='nav-link'> History </Link>
    }
  }

  const showHistoryRoute = (typeUser) => {
      if(typeUser === "employee") {
          return <Route  path='/history' element={<History key="history"/>} />
      }
  }

  const handleLogout = async () => {
      setSessionToken(false)
      let dataLogout = localStorage.getItem('token')
      await logout(dataLogout)
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      localStorage.removeItem("username")
      localStorage.removeItem("type_user")
      setSessionToken(localStorage.getItem('token'))
  }
  console.log(`working at: ${Date()}, checkHealth testing production`)

  return (
    <Router>
      <Navbar expand="sm" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/form">VRS</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav me-auto">
                {/* {sessionToken && */}
                    <>
                        <Link to="/pull" className='nav-link'>Pull Records</Link>
                        <Link to="/push" className='nav-link'>Push Records</Link>
                        <Link to="/form" className='nav-link'>Add company</Link>
                        <Link to="/form_gtin" className='nav-link'> Data Verification</Link>
                        {/* <Link to="/my_history" className='nav-link'> My history</Link> */}
                        {showHistoryNav(typeUser)}
                        {/* <Link to="/" className='nav-link' onClick={handleLogout}> Logout </Link> */}
                    </>
                {/* } */}
                {/* {!sessionToken  &&  */}
                  {/* <> */}
                      {/* <Link to="/login" className='nav-link'> Login </Link> */}
                      {/* <Link to="/sign_up" className='nav-link'> Sign Up </Link> */}
                  {/* </> */}
                {/* } */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
          {/* {!sessionToken &&
              <Route path='/' exact element={<SignIn set={setSessionToken} user={setUser} setType={setTypeUser} />}/>
          } */}
        {/* {sessionToken  && 
          <> */}
             <Route path='/' exact element={<FormGtin user={user} />}  />
             <Route path='/form_gtin' element={<FormGtin user={user} />}  />
             {/* <Route path='/my_history' element={<History user={user} key="my_history" />} /> */}
             <Route path='/pull' element={<PullRecords data={user} />} />
             <Route path='/push' element={<FormSync data={user} />} />
             <Route path='/form' element={<SourceCompany data={user} />} />
             {showHistoryRoute(typeUser)}
          {/* </>
        } */}
        {/* {!sessionToken  && 
          <>
            <Route path='/login' element={<SignIn/>} /> */}
            {/* <Route path='/sign_up' element={<SignUp/>} /> */}
          {/* </>
        } */}
      </Routes>
      </Router>
  );
}

export default App;
