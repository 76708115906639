// react
import React, { useState } from 'react';
import Result from './result';
import { v4 as uuidv4 } from 'uuid';
// react-boostrap
import { Form, Button, Row, Col, Container } from 'react-bootstrap';

// services
import { pushSync } from '../actions/herculesrx'

// css_components
import './styles/form.css'


const FormSync = (data) => {
    const [ gtinFormat, setGtinFormat ] = useState(false);
    const [ showModal, setShowModal ] = useState(false)
    const [ validForm, setValidForm ] = useState(false);
    const [ status, setStatus ] = useState('')
    const [result, setResult] = useState({
        color: '',
        result: '',
        message: ''
    })

    // Handle patterns
    const handleGtinFormat = e => {
        if(/^\d{14}$/.test(e.target.value)){
            setGtinFormat(false)
            return
        }
        setGtinFormat(true)
    };

    const handleSelect = (e) =>{
        setStatus(e.target.value)        
    }

    const ciValidation = (e) =>{
        console.log(e)
    }


    const handleSubmitGtin = async (e) => {
        e.preventDefault();
        const form = e.target
        if(true){
            let data = {
                recordGuid: uuidv4(),
                recordOwner: form.recordOwner.value,
                gtin: form.gtin.value,
                ci: form.ci.value,
                sourceVrsId : "VRS-121",
                startExpDate: form.startExpDate.value.slice(2).replaceAll('-', ''),
                endExpDate: form.endExpDate.value.slice(2).replaceAll('-', ''),
                status: status,
                nextRecordOwner: form.nextRecordOwner.value,
                lastModifiedDateTime: form.lastModifiedDateTime.value.slice(2).replaceAll('-', '')
            }
            const resulting = await pushSync(data)
            setShowModal(true)

            let resultado = resulting["result"]  ? "Verified!!" : "No Verified"

            if(resulting["message"] === "Invalid data") {
                resultado =  "Invalid"
            }

            setResult({
                color: 'success',
                result: resultado,
                message: resulting["message"]
            })
        }else {
            setValidForm(true)
            setResult({
                color: 'warning',
                message: 'Invalid!!!'
            })
            return
        }
    }


    return (
        <div className="container mt-5 pl-5 pr-5"  max-width="500px" >
            <Result show={showModal} change={setShowModal} response={result}/>
            <Form  onSubmit={handleSubmitGtin}>
                {/* <div className=''>
                <Button variant="primary" type="button" className='m-2'>
                   Update 
                </Button>
                <Button variant="primary" type="button" className='m-2'>
                   New 
                </Button>
                </div> */}
                <Form.Group className="mb-3 mt-3" controlid="gtin">
                    <Form.Label>GTIN:</Form.Label>
                    <Form.Control type="text" placeholder="Enter GTIN"
                                  onChange={e => handleGtinFormat(e)}  name="gtin"/>
                    {gtinFormat &&
                        <div className="invalid_format">
                            Should have 14 numeric characters
                        </div>
                    }
                </Form.Group>

                <Form.Group className="mb-3 mt-3" controlid="ci">
                    <Form.Label>Ci:</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Example: http://example.com"  
                        onChange={ciValidation}
                        name="ci"/>
                </Form.Group>
                <Form.Group className="mb-3" controlid="recordOwner">
                     <Container>
                            <Row>
                                <Col>
                                <Form.Label>Record Owner:</Form.Label>
                                <Form.Control type="text" placeholder="Record Owner#" name="nextRecordOwner"/>
                                </Col>
                                <Col>
                                <Form.Label>Next Record Owner:</Form.Label>
                                <Form.Control type="next" placeholder="Next Record Owner#" name="recordOwner" />
                                </Col>
                            </Row>
                        </Container>
                </Form.Group>
                <Form.Group className="mb-3" controlid="controlDate">
                    <Container>
                            <Row>
                                <Col>
                                <Form.Label>start Date:</Form.Label>
                                <Form.Control type="date" name="startExpDate" />
                                </Col>
                                <Col>
                                <Form.Label>end Date:</Form.Label>
                                <Form.Control type="date" name="endExpDate"/>
                                </Col>
                            </Row>
                        </Container>
                </Form.Group>
                <Form.Group className="mb-3" controlid="status">
                    <Container>
                        <Row>
                            <Col>
                                <Form.Label>Status:</Form.Label>
                                <Form.Select onChange={handleSelect} name='selectStatus' >
                                    <option>Select a status:</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="deleted">Deleted</option>
                                </Form.Select>
                            </Col>

                            <Col>
                                <Form.Label>Last modified time:</Form.Label>
                                <Form.Control type="date" name="lastModifiedDateTime"/>
                            </Col>
                        </Row>
                    </Container>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                {validForm &&
                    <div className="invalid_format">
                        All the fields are required
                    </div>
                }
            </Form>
        </div>
    )
}

export default FormSync
