import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { syncCia } from "../actions/herculesrx";


const SourceCompany = () =>{
    const API_URL = process.env.REACT_APP_BASE_URL + '/v1/ld/sourcecompany';
    const [ validVrsId, setValidVrsId ] = useState({
        valid: false,
        message: "",
    }) 

    const [ validSourceName, setValidSourceName ] = useState({
        valid: false,
        message: "",
    }) 

    const [ validSourceUrl, setValidSourceUrl ] = useState({
        valid: false,
        message: "",
    }) 

    const [ validEmail, setValidEmail ] = useState({
        valid: false,
        message: "",
    }) 

    const [ validForm, setValidForm ] = useState({
        valid: false,
        message: "",
    })

    // const [ validCertificate, setValidCertificate ] = useState({
    //     valid: false,
    //     message: "",
    //     value: ""
    // })

    const [ validPublicKey, setValidPublicKey ] = useState({
        valid: false,
        message: "",
        value: ""
    })

    // const [ validPfxFile, setValidPfxFile ] = useState({
    //     valid: false,
    //     message: "",
    //     value: ""
    // })

    // const [ validPassphrase, setValidPassphrase ] = useState({
    //     valid: false,
    //     message: ""
    // })


    const navigate = useNavigate()    

    const vrsIdValidator = (e) =>{
        let message = ""
        const value = e.target.value
        if(value.length !== 0 && value.length < 20 && value.length > 3){
            message = "VrsId name is valid"
            setValidVrsId({
                valid: true,
                message: message
            })
        } else {
            if(value.length <= 3) message = "VrsId name is too short"
            if(value.length === 0) message = "VrsId name is required"
            if(value.length > 20) message = "VrsId name is too long"
            setValidVrsId({
                valid: false,
                message: message
            })
        }
    }

    const nameValidator = (e) =>{
        let message = ""
        const value = e.target.value
        if(value.length !== 0 && value.length <= 80 && value.length > 3){
            setValidSourceName({
                valid: true,
                message: "Source name is valid"
            })
        } else {
            if(value.length <= 3) message = "Source name is too short"
            if(value.length === 0) message = "Source name is required"
            if(value.length > 80) message = "Source name is too long"
            setValidSourceName({
                valid: false,
                message: message
            })
        }
    }

    const urlValidator = (e) =>{
        let message = ""
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-_.~:/?#[\]@!$&'()*+,;=]*)?$/i;
        const value = e.target.value;
        const match = value.match(urlRegex)
        if(value.length !== 0 && match){
            setValidSourceUrl({
                valid: true,
                message: "Source url is valid"
            })
        } else {
            if(!match) message = "Source url is not valid"
            if(value.length === 0) message = "Source url is required"
            setValidSourceUrl({
                valid: false,
                message: message
            })  
        }
    }

    const emailValidator = (e) =>{
        let message = ""
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        const value = e.target.value
        const match = value.match(emailRegex)
        if(value.length < 80 && value.length !== 0 && match){
            setValidEmail({
                valid: true,
                message: "Email is valid"
            })
        } else {
            if(value.length === 0) message = "Email is required"
            if(value.length > 80) message = "Email is too long"
            if(!match) message = "Email is not valid"
            setValidEmail({
                valid: false,
                message: message
            })
        }
    }
 

    const handleSubmit = async (e) =>{
        e.preventDefault();
        const form = document.getElementById('form-company')
        if(validForm.valid){
            const syncCompany = async (data) => {
                const payload = {
                    method: "POST",
                    headers: {
                        "Authorization": `token ${localStorage.getItem('token')}`
                    },
                    body: data
                }
                const response = await fetch(API_URL, payload);
                const jsonResponse = await response.json()
                return jsonResponse;
            }
            const dataForm = new FormData()
            dataForm.append("sourceVrsId", form.sourceVrsId.value)
            dataForm.append("source_name", form.sourcename.value)
            dataForm.append("source_url", form.sourceurl.value)
            dataForm.append("email", form.email.value)
            // dataForm.append("certificate", validCertificate.value)
            dataForm.append("public_key", validPublicKey.value)
            // dataForm.append("pfx_file", validPfxFile.value)
            // dataForm.append("passphrase", form.passphrase.value)
            const results = await syncCompany(dataForm)
            if(results.data) {
                navigate("/pull") 
            } else {
                console.log(results)
                const errors = Object.keys(results).toString()
                setValidForm({valid: false, message: `${errors} already exist`})
            }
            // const token = results.token
            // console.log(results)
            
        }    
    }

    const fileValidator = (file, type, extension) => {
        if(file === undefined) return ({ valid: false, message: "" })
        let valid = true 
        let message = "File is valid"
        if(file.size > 4096){
            valid = false 
            message = "File size is too big"
        } 
        if(file.type !== type)
        { 
            valid = false 
            message = `File with ${file.name.split('.')[1]} extension is not valid. Required ${extension} extension`
        }
        return { valid: valid, message: message, value: file}
    }

    // const certificateHandler = (e) =>{
    //     setValidCertificate(fileValidator(e.target.files[0], "application/pkix-cert", ".crt"))
    // }

    const publicKeyHandler = (e) =>{
        setValidPublicKey(fileValidator(e.target.files[0], "application/x-x509-ca-cert", ".pem"))
    }

    // const pfxfileHandler = (e) =>{
    //     setValidPfxFile(fileValidator(e.target.files[0], "application/x-pkcs12", ".pfx"))
    // }
    // const passphraseHandler = (e) =>{
    //     const value = e.target.value
    //     let message = "Passphrase is valid"
    //     const passphraseRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    //     const match = value.match(passphraseRegex)
    //     if((value.length < 80 && match) || value.length === 0){
    //         if(value.length === 0) message = ""   
    //         setValidPassphrase({
    //             valid: true,
    //             message: message 
    //         })
    //     } else {
    //         if(value.length > 80) message = "Passphrase is too long"
    //         if(!match) message = "Passphrase must contain at least 8 characters, one uppercase, one lowercase and one number"
    //         setValidPassphrase({
    //             valid: false,
    //             message: message 
    //         })
    //     }
    // }

    useEffect(() => {
        if(validVrsId.valid && 
            validSourceName.valid && 
            validSourceUrl.valid && 
            validEmail.valid && 
            // validCertificate.valid && 
            validPublicKey.valid
        )
        {
            setValidForm({
                valid: true,
                message: "Form is valid"
            })
        } else {
            let message = ""
            if(!validPublicKey.valid) message = "Public key is not valid"
            // if(!validCertificate.valid) message = "Certificate is not valid"
            if(!validEmail.valid) message = "Email is not valid"
            if(!validSourceUrl.valid) message = "Source url is not valid"
            if(!validSourceName.valid) message = "Source name is not valid"
            if(!validVrsId.valid) message = "VrsId is not valid"
            setValidForm({
                valid: false,
                message: message
            })
        }
    }, [
        validVrsId,
        validSourceName,
        validSourceUrl,
        validEmail,
        // validCertificate, 
        validPublicKey, 
        // validPfxFile, 
        // validPassphrase
    ])

    return(
        <div className="container mt-5 pl-5 pr-5">
            <Form id="form-company" > 
                <Form.Group className="mb-3">
                    <Form.Label>Source Vrs ID:</Form.Label>
                    <Form.Control type='text' placeholder="SourceVrsId" name="sourceVrsId" onChange={vrsIdValidator} />
                    <div>
                        <small className={`${validVrsId.valid ? "text-success":"text-danger"}`}>
                            {validVrsId.valid ? validVrsId.message : validVrsId.message}
                        </small>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Source Name:</Form.Label>
                    <Form.Control type='text' placeholder="SourceName" name="sourcename" onChange={nameValidator} />
                    <div>
                        <small className={`${validSourceName.valid ? "text-success":"text-danger"}`}>
                            {validSourceName.valid ? validSourceName.message : validSourceName.message}
                        </small>
                    </div>
                </Form.Group >
                <Form.Group className="mb-3">
                    <Form.Label>Source Url:</Form.Label>
                    <Form.Control type='text' placeholder="SourceUrl" name="sourceurl" onChange={urlValidator} />
                    <div>
                        <small className={`${validSourceUrl.valid ? "text-success":"text-danger"}`}>
                            {validSourceUrl.valid ? validSourceUrl.message : validSourceUrl.message}
                        </small>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type='email' placeholder="Email" name="email" onChange={emailValidator} />
                    <div>
                        <small className={`${validEmail.valid ? "text-success":"text-danger"}`}>
                            {validEmail.valid ? validEmail.message : validEmail.message}
                        </small>
                    </div>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Label>Certificate:</Form.Label>
                    <Form.Control type='file' placeholder="Certificate" id="certificate" name="certificate" onChange={certificateHandler}/>
                    <div>
                        <small className={`${validCertificate.valid ? "text-success":"text-danger"}`}>
                        {validCertificate.valid? validCertificate.message: validCertificate.message}
                        </small>
                    </div>
                </Form.Group> */}
                <Form.Group className="mb-3">
                    <Form.Label>Public Key:</Form.Label>
                    <Form.Control type='file' placeholder="Public key" id="public_key" name="public_key" onChange={publicKeyHandler}/>
                    <div>
                        <small className={`${validPublicKey.valid ? "text-success":"text-danger"}`}>
                        {validPublicKey.valid? validPublicKey.message: validPublicKey.message}
                        </small>
                    </div>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Label>Pfx file(optional):</Form.Label>
                    <Form.Control type='file' placeholder="pfx file" id="pfxfile" name="pfxfile" onChange={pfxfileHandler}/>
                    <div>
                        <small className={`${validPfxFile.valid ? "text-success":"text-danger"}`}>
                        {validPfxFile.valid? validPfxFile.message: validPfxFile.message}
                        </small>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Passphrase(optional):</Form.Label>
                    <Form.Control type='text' placeholder="passphrase" name="passphrase" onChange={passphraseHandler} />
                    <div>
                        <small className={`${validPassphrase.valid ? "text-success":"text-danger"}`}>
                        {validPassphrase.valid? validPassphrase.message: validPassphrase.message}
                        </small>
                    </div>
                </Form.Group> */}
                <div className="mb-3">
                    <small className={`${validForm.valid ? "text-success": "text-danger"}`}>
                    {validForm.valid ? validForm.message : validForm.message}
                    </small>
                </div>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default SourceCompany;