// react
import React, { useEffect, useState} from 'react'
// react-boostrap
import { Table, Pagination } from 'react-bootstrap';
// libraries
import moment from 'moment';
// services
// css_components
import { history as myHistory, historyUser } from '../actions/herculesrx';
import './styles/history.css'


const History = (data={}) => {
    const [history, setHistory] = useState([
        {
            "user_id": "",
            "request_time": new Date(),
            "exp_date": "-------"
        }
    ])
    const [historyRaw, setHistoryRaw] = useState([])
    const [active, setActive] = useState(1)
    const [counts, setCounts] = useState([1])
    // const changeResult = (bol) => {
    //     return bol ? 'yes': 'no'
    // }


    //load data
    useEffect( () => {
        const fetchData = async ()  => {
            if(!Object.keys(data).length){
                const responseHistory = await myHistory(active) 
                // set pagination
                let arr = []
                for(let idxe = 1; idxe < responseHistory["count"]/20 + 1 ; idxe++){
                    arr.push(idxe)
                }
                setCounts(arr)
                if(responseHistory["results"]) {
                setHistory(responseHistory["results"])
                setHistoryRaw(responseHistory["results"])
                }
            }else {
                const responseHistory = await historyUser(active)
                // set pagination
                let arr = []
                for(let idxe = 1; idxe < responseHistory["count"]/20 + 1 ; idxe++){
                    arr.push(idxe)
                }
                setCounts(arr)
                setHistory(responseHistory["results"])
                setHistoryRaw(responseHistory["results"])
            }
        }

        try {
            fetchData()
        } catch(error) {
            return
        }

    }, [])

    const handleFilter = async (e) => {

        if (e.target.value === "") {
            setHistory(historyRaw)
            return
        }
        let responseFilter
        if(Object.keys(data).length ) {
             responseFilter = await myHistory(1, e.target.value)
        }else {
             responseFilter = await historyUser(1, e.target.value)
        }

        const responseHistoryFilter = await responseFilter.json()

        // set pagination
        let arr = []
        for(let idxe = 1; idxe < responseHistoryFilter["count"]/20 + 1 ; idxe++){
            arr.push(idxe)
        }
        setCounts(arr)
        setHistory(responseHistoryFilter["results"])
    }

    const  changePage = async (e) => {
        // no make fetch if is the same active page
        if (e.target.tabIndex === active) {
            return
        }
        let response
        if(!Object.keys(data).length ) {
            response = await myHistory(e.target.tabIndex)
        }else {
            response = await historyUser(e.target.tabIndex)
        }
        const responseHistory = await response.json()

        // set pagination
        setActive(e.target.tabIndex)
        let arr = []
        for(let idxe = 1; idxe < responseHistory["count"]/20 + 1 ; idxe++){
            arr.push(idxe)
        }
        setCounts(arr)

        setHistory(responseHistory["results"])
        setHistoryRaw(responseHistory["results"])
    }

    return (
        <div className="container">
            <h3 className="mt-4"> History Validation</h3>
            <input className="form-control mt-4 input" id="myInput" type="text" placeholder="Search in gtin, expiration date, serial number and result" onChange={(e) => handleFilter(e)} />
            <Table className="mt-4 table-dark"striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>User ID</th>
                    <th>Gtin Number</th>
                    <th>Verification Date</th>
                    <th>Serial Number</th>
                    <th>Lot Number</th>
                    <th>Expiration Date</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                    history.map((row, index) => {
                        return (
                        <tr key={index}>
                            <td>{row.user_id}</td>
                            <td>{row.gtin}</td>
                            <td>{ moment(new Date(row.request_time)).format('YYYY-MM-DD')}</td>
                            <td>{row.serial_number}</td>
                            <td>{row.lot_number}</td>
                            <td>{row.exp_date[4] + row.exp_date[5]
                                + "/" + row.exp_date[2] + row.exp_date[3]
                                + "/" + row.exp_date[0] + row.exp_date[1]}</td>
                            <td className={row.result ? 'green-row' : 'red-row'}>{row.result ? 'true' : 'false'}</td>
                        </tr>)
                    })
                }
                </tbody>
            </Table>
            <div>
                <Pagination size="sm" >
                    { counts.map((number, index) => {
                        return  <Pagination.Item key={index} tabIndex={number} active={number ===  active} onClick={(el) => changePage(el)}>
                            {number}
                        </Pagination.Item>
                    })}
                </Pagination>
            </div>
        </div>
    )
}

export default History

