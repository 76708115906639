import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const Result = (data={}) => {

    const handleClose = () => data["change"](false);

    return (
        <>
            <Modal show={data["show"]} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{data["response"]["result"]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{data["response"]["message"]}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Result;