import { BaseService } from "./baseServices";

export default class HerculesRx extends BaseService{
    static async fetchLogin(body){
        const url = 'login/'
        const response = await this.baseRequest(url, 'POST', body, null);
        return response;
    }

    static async fetchLogout(tokenSession){
        const url = 'logout/' + '?token=' + tokenSession
        const response = await this.baseRequest(url, 'GET', null, null);
        return response;
    }

    static async fetchPushSync(data){
        const url = 'v1/ld/pushsynchronization'
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }

    static async fetchVerification(url_query, data){
        const url = `vrs/1.0/gtin/${data.gtin}/lot/${data.lot_number}/ser/${data.serial_number}` + url_query
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }

    static async fetchGtin(data){
        const url = 'vrs/1.0/verify_data'
        const response = await this.baseRequest(url,'POST' ,data , null);
        return response;
    }

    static async fetchUser(data){
        const url = 'user/'
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }

    static async fetchUserHistory(data){
        const url = 'vrs/1.0/history_user'
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }

    static async fetchHistory(data){
        const url = 'vrs/1.0/history'
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }

    static async fetchBarCode(data){
        const url = 'vrs/1.0/verify_barcode'
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }

    static async fetchSyncCia(data){
        const url = 'v1/ld/sourcecompany'
        const headers = {
            "Content-Type": "multipart/form-data"
        }
        const response = await this.baseRequest(url, 'POST', data, null);
        return response;
    }
}