// react
import React, { useState } from 'react';
import Result from './result';

// react-boostrap
import { Form, Button, Row, Col, Container } from 'react-bootstrap';

// services
import { barCode } from '../actions/herculesrx';
import { gs1Verification } from '../actions/herculesrx';
import { gtinVerification } from '../actions/herculesrx';

// css_components
import './styles/form.css'


const FormGtin = (data) => {
    const [ gtinFormat, setGtinFormat ] = useState(false);
    const [ monthFormat, setMonthFormat ] = useState(false);
    const [ showModal, setShowModal ] = useState(false)
    const [ yearFormat, setYearFormat ] = useState(false);
    const [ validForm, setValidForm ] = useState(false);
    const [ fieldForm, setFieldForm ] = useState(false);
    const [ formChange, setFormChange ] = useState("Barcode")
    const [ gs1Format, setGs1Format ] = useState(false)
    const [ context, setContext ] = useState("")
    const [result, setResult] = useState({
        color: '',
        result: '',
        message: ''
    })

    const fetchBarcodeVerification = async (data)  => {
        const response = await barCode(data)
        const responseBarcode = await response.json()
        return responseBarcode
    }
    
    // Handle patterns
    const handleGtinFormat = e => {
        if(/^\d{14}$/.test(e.target.value)){
            setGtinFormat(false)
            return
        }
        setGtinFormat(true)
    };


    const handleMonthFormat = form => {
        if(form.month.value !== 'Select a month'){
            setMonthFormat(false)
            return
        }
        setMonthFormat(true)
    };

    const handleYearFormat = form => {
        if(form.year.valuee !== 'Select a year'){
            setYearFormat(false)
            return
        }
        setYearFormat(true)
    };

    const yearGenerator = () => {

        let years = []
        for(let y= 2000; y<2100; y++){
            years.push(y)
        }
        return years
    }

    // Change Form
    const handleChangeForm = e => {
        formChange === "Barcode" ?  setFormChange("Gtin") : setFormChange("Barcode")
    }

    const handleSelect = (e) =>{
        setContext(e.target.value)        
    }

    // Handle form
    const handleSubmitBarcode = async (e) => {
        e.preventDefault();
        const form = e.target

        if( form.barcode.value) {
            // getting Data from VRS System
            const dataBarcode = {
                "username": localStorage.getItem('username'),
                "barcode": form.barcode.value
            }
            try {
                const resulting = await fetchBarcodeVerification(dataBarcode)
                setShowModal(true)

                let resultado = resulting["result"]  ? "Verified!!" : "No Verified"

                if( resulting["message"] === "Invalid data") {
                    resultado =  "Invalid"
                }

                setResult({
                    color: 'success',
                    result: resultado,
                    message: resulting["message"]
                })

                form.barcode.value = ""
            } catch (error) {
                setShowModal(true)
                setResult({
                    color: 'Invalid',
                    result: "Invalid",
                    message: "Invalid request"
                })
            }

        }else {
            setValidForm(true)
            setResult({
                color: 'warning',
                message: 'Invalid!!!'
            })
            return
        }
    }

    const handleSubmitGtin = async (e) => {
        e.preventDefault();
        const form = e.target

        handleMonthFormat(form)
        handleYearFormat(form)

        if(gtinFormat) {
            setFieldForm(true)
            return
        }
        if( form.gtin.value
            && form.lot.value
            && form.serial.value
            && form.month.value !== 'Select a month'
            && form.year.value !== 'Select a year' ) {

            // hide the error
            setValidForm(false)

            // getting Data from VRS System
            const dataForm = {
                "username": localStorage.getItem('username'),
                "gtin": form.gtin.value,
                "serial_number": form.serial.value,
                "lot_number": form.lot.value,
                "exp_date": form.year.value[2] + form.year.value[3] + form.month.value + '01'
            }

            if(gs1Format){
                dataForm.control_possession = form.controlPossession.checked
                dataForm.context = context
                dataForm.email = form.email.value
                dataForm.telephone = form.telephone.value
                dataForm.gs1_format = gs1Format
                dataForm.reqGLN = form.reqGLN.value 
                dataForm.checkConnectivity = form.checkConnectivity.checked
            }

            try {
                let resulting = null
                if(gs1Format){
                    let url_query = `?exp=${dataForm.exp_date}&linkType=verificationService&context=${dataForm.context}&reqGLN=${dataForm.reqGLN}`
                    if(dataForm.checkConnectivity){
                        url_query += `&checkConnectivity=${dataForm.checkConnectivity}`
                    }

                    resulting = await gs1Verification(url_query, dataForm)
                }
                else{
                    resulting = await gtinVerification(dataForm)
                }
                setShowModal(true)
                if(resulting['data'] && dataForm.checkConnectivity) 
                    setResult({
                        color: 'success',
                        result: "Responder GLN",
                        message: resulting["data"].responderGLN
                    })
                else if(!dataForm.checkConnectivity){
                    setResult({
                        color: 'success',
                        result: "verification",
                        message: `Contact Phone: ${resulting.data.contactPoint.telephone}\n\n
                        Contact email: ${resulting.data.contactPoint.email}\n\n
                        corrUUID: ${resulting.data.corrUUID}\n\n
                        responderGNL: ${resulting.data.responderGLN}\n\n
                        verified: ${resulting.data.data.verified}\n\n
                        verification failure: ${resulting.data.data.verificationFailureReason}
                        `
                    })
                }
                
                form.gtin.value = ""
                form.lot.value = ""
                form.serial.value = ""
            } catch (error) {
                setShowModal(true)
                setResult({
                    color: 'Invalid',
                    result: "Invalid",
                    message: "Invalid request"
                })
            }

        }else {
            setValidForm(true)
            setResult({
                color: 'warning',
                message: 'Invalid!!!'
            })
            return
        }
    }


    return (
        <div className="container mt-5 pl-5 pr-5"  max-width="500px" >

            <Result show={showModal} change={setShowModal} response={result}/>

            <div className="row justify-content-between">
                <div className="mt-2 mb-2">
                    <Button variant="dark" style={{"width": "90px"}} onClick={handleChangeForm}>{formChange} </Button>
                </div>
            </div>
            {
                formChange === 'Gtin' &&
                <Form  onSubmit={handleSubmitBarcode}>
                    <Form.Group className="mb-3" controlId="formBasicBarcode">
                        <Form.Label>Barcode:</Form.Label>
                        <Form.Control type="text" placeholder="Enter barcode" name="barcode"/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    {validForm &&
                        <div className="invalid_format">
                            All the fields are required
                        </div>
                    }
                    {fieldForm &&
                        <div className="invalid_format">
                            All the fields should accomplish the format
                        </div>
                    }
                </Form>
            }
            {
                formChange === 'Barcode' &&
                <Form  onSubmit={handleSubmitGtin}>
                <Form.Group className='mt-3'>
                    <Form.Label className='mb-3' controlid="version">Select Specification:</Form.Label>
                    <Form.Group className='d-flex gap-3'>
                        <Form.Check 
                            type="radio" 
                            label="GS1" 
                            onChange={()=> setGs1Format(true)}
                            name="specification"/>
                        <Form.Check 
                            type="radio"
                            onChange={()=> setGs1Format(false)}
                            label="standard" 
                            name="specification"/>
                    </Form.Group>
                </Form.Group>
                <Form.Group className='mb-3 mt-3'>
                    {gs1Format?
                    <Form.Check 
                        type="checkbox"
                        label="Check Connectivity?" 
                        name="checkConnectivity"/>
                    : ""
                    }
                </Form.Group>
                <Form.Group className="mb-3 mt-3" controlid="formBasicGtin">
                    <Form.Label>GTIN:</Form.Label>
                    <Form.Control type="text" placeholder="Enter GTIN"
                                  onChange={e => handleGtinFormat(e)}  name="gtin"/>
                    {gtinFormat &&
                        <div className="invalid_format">
                            Should have 14 numeric characters
                        </div>
                    }
                </Form.Group>
                <Form.Group className="mb-3" controlid="formBasicSerial">
                    <Form.Label>Serial number:</Form.Label>
                    <Form.Control type="text" placeholder="Enter serial number#" name="serial"/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLot">
                    <Form.Label>Lot number:</Form.Label>
                    <Form.Control type="text" placeholder="Enter Lot#" name="lot"/>
                </Form.Group>
                { gs1Format?
                    <>
                    <Form.Group className="mb-3" controlid="controlPossession">
                        <Form.Label>Required GLN:</Form.Label>
                        <Form.Control type="text" placeholder="Required GLN" name="reqGLN"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlid="controlPossession">
                        <Container>
                            <Form.Check type="checkbox" label="Control Possession?" name="controlPossession"/>
                        </Container>
                    </Form.Group>
                    <Form.Group className="mb-3" controlid="context">
                        <Form.Label>Context:</Form.Label>
                        <Form.Select onChange={handleSelect} >
                            <option>Select a Context:</option>
                            <option value="dscsaSaleableReturn">Saleable Return</option>
                            <option value="dscsaSuspectIllegitimate">Suspect Illegitimate</option>
                            <option value="dscsaExceptionVerification">Exception Verification</option>
                            <option value="dscsaStatusCheck">Status Check</option>
                        </Form.Select>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlid="email">
                        <Container>
                            <Row>
                                <Col>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" placeholder="Email" name="email"/>
                                </Col>
                                <Col>
                                <Form.Label>Telephone:</Form.Label>
                                <Form.Control type="text" placeholder="Telephone" name="telephone" />
                                </Col>
                            </Row>
                        </Container>
                    </Form.Group>
                    </>
                    : <></>
                }
                <Form.Group className="mb-3" controlid="formBasicLot">
                    <div className="row">
                        <Form.Label>Expiration date:</Form.Label>
                        <div className="col">
                            <select defaultValue="optionSelected" className="form-select" aria-label="Default select example" name="month">
                                <option value="optionSelected">Select a month</option>
                                <option value="01">January</option>
                                <option value="02">Febrary</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div className="col">
                            <select defaultValue="default" className="form-select" aria-label="Default select example" name="year">
                                <option value="default">Select a year</option>
                                {yearGenerator().map( (year) => {
                                    return (<option key={year} value={year}>{year}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {(monthFormat || yearFormat) &&
                        <div className="invalid_format">
                            Select a month and year
                        </div>
                    }
                </Form.Group>
                <Button variant="primary" type="submit" className='mb-3'>
                    Submit
                </Button>
                {validForm &&
                    <div className="invalid_format">
                        All the fields are required
                    </div>
                }
                {fieldForm &&
                    <div className="invalid_format">
                        All the fields should accomplish the format
                    </div>
                }
            </Form>
            }
            
        </div>
    )
}

export default FormGtin